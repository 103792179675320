import React from "react"
import CtaSlider from "./cta-slider"
import Headings from "./headings"

const BlockCta = ({ data }) => {
    //console.log("Block CTA: ", data)
  
    const callToActionSections = data.call_to_action_sections

    return (
        <section className="uk-section uk-section-muted uk-padding-remove">
            <div className="uk-container uk-margin-medium-bottom">
                <Headings
                    title={data?.title}
                    description={data?.description}
                />
                <div className="uk-container uk-margin">
                    <CtaSlider callToActionSections={callToActionSections} />
                </div>
            </div>
        </section>
    )
}

export default BlockCta
