import React from "react"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const Hero = ({ hero }) => {
  //console.log("Hero component hero:", hero)
  //console.log("Hero component hero buttons:", hero.buttons)
  //console.log("Hero component image:", hero.Image)

  return (
    <section id="home">
      <div className="uk-cover-container ">
        <GatsbyImage
          image={getImage(hero?.image?.localFile)}
          alt="Adu dev hero image"
          style={{ height: "650px" }}
        />

        <div className="uk-overlay uk-overlay-primary uk-position-cover uk-flex uk-flex-center uk-flex-middle uk-light uk-text-center">
          <div className="uk-flex uk-flex-center">
            <div className="uk-width-2-3@s uk-width-3-3@m">
             {hero.logo ? (
                <div className=" uk-text-center uk-animation-fade uk-animation-slide-top uk-margin-small-bottom">
                  <GatsbyImage
                    image={getImage(hero?.logo?.localFile)}
                    alt="James Walter Raymond Ltd logo"
                    style={{ maxWidth: "750px" }}
                  />
                </div>
              ) : (
                <div className="uk-heading-medium uk-heading-line uk-text-center uk-light uk-animation-fade uk-animation-slide-right-medium medium uk-margin-small-top">
                   <span>{hero.title}</span>
                </div>
              )}
              <div className="uk-heading-line uk-light uk-animation-fade uk-animation-slide-right-medium medium">
                <h4
                  className="green-grad"
                  style={{ fontWeight: "700", fontSize: "26px" }}
                >
                  <span>{hero.title}</span>
                </h4>                  
              </div>
             {/*  <div className="uk-heading-medium uk-heading-line uk-text-center uk-light uk-animation-fade uk-animation-slide-right-medium medium uk-margin-small-top">
                  <span>{hero.title}</span>
              </div> */}
              <div className="uk-animation-fade uk-animation-slide-left-medium" 
                style={{
                  color: "#FFFFFF",
                  fontSize: "1.1rem",
                  
                  textShadow: "2px 2px 4px #000000",
                }}
              >
                {hero.callToAction}
              </div>
              {/* <div className="uk-animation-fade uk-animation-slide-left-medium uk-hidden@s">
                {hero.callToAction}
              </div> */}
              <div className="uk-flex uk-flex-center uk-flex-middle uk-visible@s">
                <div className="uk-margin-small-bottom">
                  {hero?.Buttons?.map(button => {
                    //console.log("Button:", button)
                    return (
                      <Link
                        key={button.strapi_id}
                        to={button.link}
                        title={button.name}
                        alt={button.alternativeText}
                        rel="noopener noreferrer"
                        target="_blank"
                        toggle="button"
                        className="uk-button uk-button-default uk-button-small uk-text-small uk-animation-fade uk-animation-slide-bottom uk-margin-small-right uk-margin-bottom uk-margin-top"
                        style={{ borderRadius: "8px", paddingTop: "5px", paddingBottom: "5px"}}
                      >
                        <span>{button.name}</span>
                      </Link>
                    )
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
export default Hero
