import React from "react"
import Markdown from "react-markdown"

const BlockRichText = ({ data }) => {
  //console.log("Data: ", data.richTextBody)
  return (
    <section className="uk-section uk-padding-remove uk-margin-remove">
      <div className="uk-container" style={{ marginTop: "15px", }}>
        <Markdown
          className="uk-padding-remove uk-margin-remove"
          children={data.richTextBody.data.body}
        />
        {/* <div
          dangerouslySetInnerHTML={{
            __html: data.richTextBody.data.body,
          }}
        /> */}
      </div>
    </section>
  )
}

export default BlockRichText
