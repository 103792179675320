import React from "react"
import { graphql } from "gatsby"
import BlockRichText from "./block-rich-text"
import BlockMedia from "./block-media"
//import BlockQuote from "./block-quote"
import BlockSlider from "./block-slider"
import BlockGallery from "./block-gallery"
import BlockAdvert from "./block-advert"
import BlockCta from "./block-cta"

//import BlockVideo from "./block-video"

const componentsMap = {
  STRAPI__COMPONENT_SHARED_RICH_TEXT: BlockRichText,
  STRAPI__COMPONENT_SHARED_MEDIA: BlockMedia,
  //STRAPI__COMPONENT_SHARED_QUOTE: BlockQuote,
  STRAPI__COMPONENT_SHARED_SLIDER: BlockSlider,
  STRAPI__COMPONENT_SHARED_GALLERY: BlockGallery,
  STRAPI__COMPONENT_SHARED_ADVERT: BlockAdvert,
  STRAPI__COMPONENT_SHARED_CALL_TO_ACTION: BlockCta,
  //STRAPI__COMPONENT_SHARED_VIDEO: BlockVideo,
}

const Block = ({ block }) => {
  const Component = componentsMap[block.__typename]

  if (!Component) {
    return null
  }

  return <Component data={block} />
}

const BlocksRenderer = ({ blocks }) => {
  //console.log("Blocks: ", blocks)
  return (
    <React.Fragment>
      {blocks.map((block, index) => (
        <Block key={`${index}${block.__typename}`} block={block} />
      ))}
    </React.Fragment>
  )
}

export const query = graphql`
  fragment Blocks on STRAPI__COMPONENT_SHARED_ADVERTSTRAPI__COMPONENT_SHARED_CALL_TO_ACTIONSTRAPI__COMPONENT_SHARED_GALLERYSTRAPI__COMPONENT_SHARED_MEDIASTRAPI__COMPONENT_SHARED_RICH_TEXTSTRAPI__COMPONENT_SHARED_SLIDERUnion {
    __typename
    ... on STRAPI__COMPONENT_SHARED_RICH_TEXT {
      richTextBody: body {
        __typename
        data {
          id
          body
        }
      }
    }
    ... on STRAPI__COMPONENT_SHARED_MEDIA {
      file {
        alternativeText
        caption
        name
        mime
        localFile {
          #url
          publicURL
          childImageSharp {
            gatsbyImageData(aspectRatio: 1.57, layout: FULL_WIDTH)
          }
        }
      }
    }
    #... on STRAPI__COMPONENT_SHARED_QUOTE {
    #  title
    #  quoteBody: body
    #}
    ... on STRAPI__COMPONENT_SHARED_SLIDER {
      files {
        id
        alternativeText
        caption
        mime
        localFile {
          url
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, aspectRatio: 1.57, height: 600)
          }
        }
      }
    }
    ... on STRAPI__COMPONENT_SHARED_GALLERY {
      title
      description
      files {
        id
        alternativeText
        caption
        mime
        localFile {
          url
          publicURL
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
    ... on STRAPI__COMPONENT_SHARED_ADVERT {
      title
      description
      position
      enabled
      secondaryHeading
      secondaryDescription
      sectionColor
      file {
        alternativeText
        localFile {
          childImageSharp {
            gatsbyImageData(formats: AUTO, layout: CONSTRAINED, placeholder: BLURRED, aspectRatio: 1.40, height: 600)
          }
        }
      }
      content {
        data {
          content
        }
      }
      advertButton {
        name
        alternativeText
        link
      }
    }
    ... on STRAPI__COMPONENT_SHARED_CALL_TO_ACTION {
      title
      description
      call_to_action_sections {
        title
        description
        link
        # image {
        #   # surl
        #   localFile {
        #     childImageSharp {
        #       gatsbyImageData(formats: AUTO)
        #     }
        #     url
        #   }
        # }
      }
    }
    #... on STRAPI__COMPONENT_SHARED_VIDEO {
    #  headings {
    #    title
    #    description
    #  }
    #  src
    #  title
    #  width
    #  height
    #}
  }
`

export default BlocksRenderer
