import React from "react"
//import Headings from "./headings"
import ReactLightbox from "./react-lightbox"

const BlockGallery = ({ data }) => {
  const files = data.files
  //console.log("Data: ", data)
  return (
    <section className="uk-section uk-section-default uk-padding-remove">
        <div className="uk-container" style={{ marginTop: "20px", marginBottom: "40px"}}>
        <div className="uk-margin-small-bottom">
          {data?.title ? (
            <h3 className="uk-margin-remove">
              {data?.title}
            </h3>
          ) : null}
        </div>

        <div className=" uk-margin-small-bottom">
          {data?.description ? (
            <p>
              {data?.description}
            </p>
          ) : null}
        </div>
        
        <ReactLightbox files={files} location="Articles" />
      </div>
    </section>
  )
}

export default BlockGallery
