import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Seo from "../components/seo"
import Headings from "../components/headings"
import Hero from "../components/hero"
import BlocksRenderer from "../components/blocks-renderer"

const PrivacyPolicy = () => {
  const { strapiPrivacy } = useStaticQuery(graphql`
    query {
      strapiPrivacy {
        hero {
          ...StrapiHero
        }
        seo {
          ...StrapiSeo
          metaSocial {
            socialNetwork
            title
            description
          }
        }
        headings {
          title
          description
        }
        blocks {
          ...Blocks
        }
      }
    }
  `)

  //console.log("strapiPrivacy: ", strapiPrivacy)

  const { hero, headings, blocks, seo } = strapiPrivacy

  const imageUrl = `${seo.metaImage.localFile.url}`

  /* console.log(
    "imageUrl: ",
    `${process.env.SITE_URL}${seo.metaImage.localFile.publicURL}`
  ) */

  return (
    <>
      <Seo
        title={seo.metaTitle}
        description={seo.metaDescription}
        image={imageUrl}
        metaSocial={seo.metaSocial}
        metaRobots={seo.metaRobots}
      />
      <Hero hero={hero} />

      <section className="uk-section uk-section-default uk-padding-remove">
        <div className="uk-container uk-margin-small-bottom">
          <Headings title={headings.title} description={headings.description} />
          <div style={{ marginTop: "20px"}}>
            <BlocksRenderer blocks={blocks} />
          </div>     
        </div>
      </section>
    </>
  )
}

export default PrivacyPolicy
